import React from "react";
import TextField from "./TextField";
import FormLabel from "./FormLabel";
import PasswordIcon from "./icons/PasswordIcon";
import { InputError } from "./ErrorLabel";

interface PasswordFieldProps {
  id: string;
  title?: string;
  placeholder?: string;
  autoComplete?: string;
  value?: string;
  error?: InputError;
  required?: boolean | undefined;
}

const PasswordField: React.FC<PasswordFieldProps> = ({
  id,
  title,
  autoComplete,
  value,
  error,
  required,
}) => {
  return (
    <TextField
      id={id}
      type="password"
      icon={<PasswordIcon />}
      title={title}
      placeholder="••••••••"
      value={value}
      autoComplete={autoComplete}
      error={error}
      required={required}
    />
  );
};

export default PasswordField;
