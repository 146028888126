interface Props {
    message: string;   
}

const ErrorToast = ({ message }: Props ) => {
  
  return (
    <div className="fixed bottom-4 right-4 bg-red-500 text-white px-4 py-2 rounded-md shadow-md">
      <p>{message}</p>
    </div>
  );
};

export default ErrorToast;