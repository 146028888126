import React from "react";

export type InputError = string | { message: string; errorCode: string }[] | { message: string; errorCode: string } | undefined;

interface Props {
  error?: InputError;
}

const ErrorLabel = ({ error }: Props) => {
  if (!error) return <></>;

  return (
    <div className="mt-2 text-red-600">
      {typeof error === "string" && error}
      {error instanceof Array &&
        error.map((error, index) => {
          return <p key={index}>{error.message}</p>;
        })}
      {typeof error === "object" && !(error instanceof Array) && error.message}
    </div>
  );
};

export default ErrorLabel;
