import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/lib/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../../../public/fonts/made_outer_sans_bold-webfont.woff\"}],\"variableName\":\"titleFont\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/lib/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../../../public/fonts/made_outer_sans_thin-webfont.woff\"}],\"variableName\":\"smallFont\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/lib/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../../../public/fonts/made_outer_sans_light-webfont.woff\"}],\"variableName\":\"smallBoldFont\"}");
;
import(/* webpackMode: "eager" */ "/app/public/images/logo.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[lang]/users/signin/components/SignInForm.tsx");
