import React from "react";
import { createPortal } from "react-dom";
import { Button } from "./Button";

interface DialogProps {
  isOpen?: boolean;
  icon?: React.ReactNode;
  title: string;
  children: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  confirmType?: "button" | "submit";
  variant?: "primary" | "danger";
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const Dialog = ({
  isOpen = false,
  icon,
  title,
  children,
  confirmText,
  cancelText,
  confirmType = "button",
  variant = "primary",
  onConfirm,
  onCancel,
}: DialogProps) => {
  if (!isOpen) return null;

  return createPortal(
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                {icon && (
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-primary sm:mx-0 sm:h-10 sm:w-10">
                    {icon}
                  </div>
                )}
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3
                    className="text-base font-semibold leading-6 text-black"
                    id="modal-title"
                  >
                    {title}
                  </h3>
                  <div className="mt-2 text-base text-black">{children}</div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 sm:gap-4">
              {confirmText && (
                <Button type={confirmType} variant={variant} onClick={onConfirm}>
                  {confirmText}
                </Button>
              )}
              {cancelText && (
                <Button type="button" variant="outlined" onClick={onCancel}>
                  {cancelText}
                </Button>
              )}
              {!confirmText && !cancelText && (
                <Button type="button" variant="outlined" onClick={onCancel}>
                  Cerrar
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};
