import React, { ReactNode } from "react";

interface LinkProps {
  url: string;
  children?: ReactNode;
}

const Link = ({ url, children }: LinkProps) => {
  return (
    <a
      href={url}
      className="font-medium text-textLink-light dark:text-textLink-dark hover:underline"
    >
      {children}
    </a>
  );
};

export default Link;
