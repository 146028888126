"use client";

import {
  type ChangeEvent,
  type InputHTMLAttributes,
  type ReactElement,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { twMerge } from "tailwind-merge";
import { BorderMode } from "./BorderMode";
import ErrorIcon from "./ErrorIcon";
import ErrorLabel, { type InputError } from "./ErrorLabel";
import FormLabel from "./FormLabel";

type TextFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  id: string;
  icon?: ReactElement;
  type?: "text" | "email" | "password" | "date" | "number" | "tel" | "url";
  title?: string;
  border?: keyof typeof BorderMode;
  placeholder?: string;
  autoComplete?: string;
  value?: string | number; // initialValue
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  error?: InputError | any;
  required?: boolean | undefined;
  storageId?: string;
  className?: string;
  fieldSize?: "sm" | "md" | "lg";
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      id,
      icon,
      type = "text",
      title,
      placeholder,
      autoComplete,
      value = "",
      error,
      required,
      storageId,
      className,
      border,
      readOnly,
      disabled,
      onChange,
      fieldSize,
      ...props
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [inputValue, setInputValue] = useState<string | number>(value);

    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    useEffect(() => {
      setInputValue(value);
    }, [value]);

    useEffect(() => {
      if (value) return;
      const storedValue = storageId && localStorage.getItem(storageId);
      storedValue && setInputValue(storedValue);
    }, [storageId]);

    const updateInputValue = (value: string | number) => {
      setInputValue(value);
      storageId && localStorage.setItem(storageId, String(value));
    };

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (type === "number") {
        const newValue = e.target.value;
        if (!newValue) {
          updateInputValue("");
          onChange?.(e);
          return;
        }
        if (/^\d*$/.test(newValue)) {
          updateInputValue(Number(newValue));
        }
        onChange?.(e);
        return;
      }
      updateInputValue(e.target.value);
      onChange?.(e);
    };

    const borderMode = (border && BorderMode[border]) ?? BorderMode.all;

    return (
      <>
        {title && (
          <FormLabel id={id} size={fieldSize}>
            {title}
          </FormLabel>
        )}
        <div className="relative text-textPlaceholder-light dark:text-textPlaceholder-dark focus-within:text-textPrimary-light dark:focus-within:text-textPrimary-dark w-full">
          {icon && (
            <div className="absolute inset-y-0 left-0 rtl:right-0 flex items-center pl-3 rtl:pr-3 pointer-events-none">
              {icon}
            </div>
          )}

          <input
            type={type}
            id={id}
            name={id}
            ref={inputRef}
            placeholder={placeholder}
            autoComplete={autoComplete}
            value={inputValue}
            required={required}
            onChange={handleOnChange}
            disabled={disabled}
            readOnly={readOnly}
            className={twMerge(
              `block w-full 
              py-1.5 ${borderMode} ${icon ? "pl-10" : ""} pr-4 rtl:pr-10 rtl:pl-4 
              shadow-sm dark:shadow-none shadow-shadow0-light 
              text-textPrimary-light  
              placeholder-textPlaceholder-light 
              transition-all duration-200 
              bg-panelField 
              focus:ring-2 ring-primaryAction-light  focus:outline-none caret-primaryAction-light 
              border-edgeField-light  ${
                error
                  ? "text-dangerAction-light  border-edgeField-light  focus:ring-dangerAction-light  focus:border-edgeField-light invalid:ring-2 invalid:ring-strokeErrorLight"
                  : "focus:border-edgeField-light   "
              } ${disabled ? "bg-panelDisabled-light " : ""} ${readOnly ? "border-edgeField-light shadow-none" : ""} `,
              className,
            )}
            {...props}
          />
          <ErrorIcon error={error} />
        </div>
        <ErrorLabel error={error} />
      </>
    );
  },
);

TextField.displayName = "TextField";

export default TextField;
