import { twMerge } from "tailwind-merge";

interface FormLabelProps {
  id?: string;
  children: React.ReactNode;
  size?: "sm" | "md" | "lg";
  className?: string;
}

const FormLabel = ({
  id,
  children,
  className,
  size = "md",
}: FormLabelProps) => {
  const fontSizeStyles = {
    sm: "text-base",
    md: "text-base",
    lg: "text-lg",
  };

  const fontSize = fontSizeStyles[size];

  return (
    <label
      htmlFor={id}
      className={twMerge(
        `${fontSize} block leading-6 font-semibold text-foreground-900 mb-2`,
        className,
      )}
    >
      {children}
    </label>
  );
};

export default FormLabel;
