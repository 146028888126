"use client";

import { Button } from "../../../../../components/Button";
import EmailField from "../../../../../components/EmailField";
import Link from "../../../../../components/Link";
import PasswordField from "../../../../../components/PasswordField";
import { useFormState, useFormStatus } from "react-dom";
import { Dialog } from "@/components/Dialog";
import ErrorToast from "@/components/ErrorToast";
import { actionUserSignIn } from "@/actions/user.signin.action";
import LoadingIcon from "@/components/icons/LoadingIcon";

interface LoginFormProps {
  strings: {
    email: { title: string; placeholder: string };
    password: { title: string };
    rememberMe: string;
    forgotPassword: string;
    noHaveAccount: string;
    signIn: string;
    signUp: string;
  };
}

const SignInForm = ({ strings }: LoginFormProps) => {
  const [response, formAction] = useFormState(actionUserSignIn, undefined);

  return (
    <form action={formAction} className="space-y-4 md:space-y-6">
      {response?.errors && typeof response.errors === "string" && (
        <Dialog title="Usuario no encontrado">{response?.errors}</Dialog>
      )}
      {response?.errors && <ErrorToast message={response?.errors} />}
      <div>
        <EmailField
          id="email"
          title={strings.email.title}
          placeholder={strings.email.placeholder}
          error={response?.errors?.fieldErrors?.email}
          autoComplete="email"
          required
        />
      </div>
      <div>
        <PasswordField
          id="password"
          title={strings.password.title}
          error={response?.errors?.fieldErrors?.password}
          autoComplete="current-password"
          required
        />
      </div>
      <div className="flex flex-col md:flex-row gap-4 items-center justify-between">
        <div className="flex items-center">
          <div className="flex items-center h-5">
            <input
              id="remember"
              aria-describedby="remember"
              type="checkbox"
              className="w-4 h-4 border border-edge1-light dark:border-edge1-dark rounded focus:ring-3 focus:ring-primaryAction-light dark:focus:ring-primaryAction-dark bg-panelField-light dark:bg-panelField-dark dark:ring-offset-edge1-dark"
            />
          </div>
          <div className="ml-3">
            <label
              htmlFor="remember"
              className="text-textPrimary-light dark:text-textPrimary-dark"
            >
              {strings.rememberMe}
            </label>
          </div>
        </div>
        <Link url="/users/forgotpassword">{strings.forgotPassword}</Link>
      </div>
      <SignInFormButtonActions text={strings.signIn} />

      <p className="font-light text-textPrimary-light dark:text-textPrimary-dark">
        {strings.noHaveAccount}{" "}
        <Link url="/users/signup">{strings.signUp}</Link>
      </p>
    </form>
  );
};

const SignInFormButtonActions = ({ text }: { text: string }) => {
  let { pending } = useFormStatus();
  return (
    <Button type="submit" fullWidth={true}>
      {pending && <LoadingIcon />}
      {!pending && text}
    </Button>
  );
};

export default SignInForm;
