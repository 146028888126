import React from "react";
import TextField from "./TextField";
import EmailIcon from "./icons/EmailIcon";
import { InputError } from "./ErrorLabel";

interface EmailFieldProps {
  id: string;
  title?: string;
  placeholder?: string;
  value?: string;
  error?: InputError;
  autoComplete?: string;
  readOnly?: boolean;
  required?: boolean | undefined;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const EmailField: React.FC<EmailFieldProps> = ({
  id,
  title,
  placeholder,
  value,
  error,
  autoComplete,
  readOnly,
  required,
  className,
  onChange
}) => {
  return (
    <TextField
      id={id}
      type="email"
      icon={<EmailIcon />}
      title={title}
      placeholder={placeholder}
      value={value}
      error={error}
      autoComplete={autoComplete}
      readOnly={readOnly}
      required={required}
      className={className}
      onChange={onChange}
    />
  );
};

export default EmailField;
